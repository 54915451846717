<template>
  <div>
    <FilterOverview title="Reiseterminübersicht" :showFilterInitially="false" @reload="onReload">
      <template #title-right>
        <b-button
          class="btn-xs btn p-2 ml-2 justify-content-center align-item-center d-flex"
          v-b-tooltip.hover
          :title="titleHoverText"
        >
          <i class="fas fa-info m-0 p-0"></i>
        </b-button>
      </template>
      <template #toolbar-left>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          style="gap: 12px"
        >
          <ReiseterminTableAggregationBarBase :filter="lastOdataFilter"></ReiseterminTableAggregationBarBase>
          <b-button-group size="sm" class="d-flex" style="gap: 2px">
            <b-button
              v-for="priority in priorities"
              :key="priority.text"
              :id="priority.text"
              @click="onPriorityClick"
              :variant="
                prioritiesSelected.includes(priority.text)
                  ? 'primary'
                  : prioritiesSelected.length === 0 && priority.text === 'Alle'
                  ? 'primary'
                  : ''
              "
            >
              {{ priority.text }}
            </b-button>
          </b-button-group>
          <b-button size="sm ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
            Alle Filter zurücksetzen
          </b-button>
        </div>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          class="ag-theme-alpine m-0 p-0"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowModelType="'serverSide'"
          :statusBar="statusBar"
          @rowDoubleClicked="onRowDoubleClicked"
          :detailCellRenderer="'DetailsReisetermineOverview'"
          :masterDetail="true"
          :detailRowAutoHeight="'true'"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked"
          :context="{ isUserAllowedToEdit: isUserAllowedToEdit }"
          suppressCellFocus
          rowHeight="50px"
        ></AgGridVue>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import ReiseterminTableAggregationBarBase from '@/components/produkte/reisetermine/reisetermin-table-aggregation-bar-base';
import FilterOverview from '@/components/common/filter-overview.vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import { vorzeitigGarantiertClass, paxFilterFormatterSmallHtml } from '@/core/common/helpers/utils';
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import { format } from 'date-fns';
import * as odataService from '@/core/common/services/odata.service';
import { add } from 'date-fns';
import { mapGetters } from 'vuex';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import RangeNumberFilterCustomHeader from '@/components/flugverfuegbarkeit/custom-header-range-number-filter.vue';
import DetailsReisetermineOverview from '@/components/produkte/reisetermine/details-reisetermine-overview.vue';

export default {
  name: 'ReisetermineOverviewComponent',
  components: {
    ReiseterminTableAggregationBarBase,
    FilterOverview,
    AgGridVue,
    StatusBarComponent,
    AgDateRangePicker,
    CellRendererPax,
    agColumnHeader: CustomHeader,
    RangeNumberFilterCustomHeader: RangeNumberFilterCustomHeader,
    DetailsReisetermineOverview,
  },
  data() {
    return {
      titleHoverText:
        'In der Reiseterminübersicht werden alle Termine gelistet, die in Blank angelegt sind. ',
      priorities: [
        { value: null, text: 'Alle' },
        { value: 0, text: 'A' },
        { value: 1, text: 'B' },
        { value: 2, text: 'C' },
        { value: 3, text: 'D' },
      ],
      prioritiesSelected: [],
      isFilterSupplied: false,
      columnDefs: null,
      gridApi: null,
      statusBar: null,
      gridColumnApi: null,
      count: null,
      lastOdataFilter: null,
      defaultColDef: {
        flex: 1,
        filter: true,
        sortable: false,
        resizable: false,
        floatingFilter: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      paxFilterFormatterSmallHtml,
    };
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        field: 'reise.reisekuerzel',
        headerName: 'Reise',
        filter: 'agSetColumnFilter',
        sort: 'asc',
        filterParams: {
          refreshValuesOnOpen: true,
          values: params => params.success(this.reisenOptions),
          debounceMs: 1000,
        },
        valueFormatter: params => params.value.substring(0, 6),
        floatingFilter: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 100,
        pinned: 'left',
      },
      {
        field: 'reise.region',
        headerName: 'Region',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => params.success(this.regionOptions),
        },
        minWidth: 105,
        maxWidth: 105,
      },
      {
        field: 'reise.country',
        headerName: 'Land',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => params.success(this.countryOptions),
          cellRenderer: params => (params.value ? params.value.replace(/([A-Z])/g, ' $1') : '-'),
        },
        cellRenderer: params => (params.value ? params.value.replace(/([A-Z])/g, ' $1') : '-'),
        minWidth: 150,
        maxWidth: 150,
      },
      {
        field: 'state',
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: [
            'Vorgeschlagen',
            'Anlagebereit',
            'CmBestaetigt',
            'CmAngefragt',
            'Durchgefuehrt',
            'Durchfuehrung',
            'Aufgelegt',
            'Abgesagt',
          ],
          valueFormatter: params =>
            params.value === 'CmBestaetigt'
              ? 'CM bestätigt'
              : params.value === 'CmAngefragt'
              ? 'CM angefragt'
              : params.value === 'Durchgefuehrt'
              ? 'Durchgeführt'
              : params.value === 'Durchfuehrung'
              ? 'Durchführung'
              : params.value,
        },
        valueFormatter: params =>
          params.value === 'CmBestaetigt'
            ? 'CM bestätigt'
            : params.value === 'CmAngefragt'
            ? 'CM angefragt'
            : params.value === 'Durchgefuehrt'
            ? 'Durchgeführt'
            : params.value === 'Durchfuehrung'
            ? 'Durchführung'
            : params.value,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        field: 'abreisedatum',
        headerName: 'Abreise',
        filter: 'AgDateRangePicker',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
        sortable: true,
        minWidth: 140,
        maxWidth: 165,
      },
      {
        field: 'startdatum',
        headerName: 'Startdatum',
        filter: 'AgDateRangePicker',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
          isReisetermineStartdatum: true,
        },
        cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
        sort: 'asc',
        sortable: true,
        minWidth: 145,
        maxWidth: 170,
      },

      {
        field: 'reisezeitraum',
        headerName: 'Reisezeitraum',
        cellRenderer: params => {
          const abreisedatum = params.data.abreisedatum ? new Date(params.data.abreisedatum) : null;
          const enddatum = params.data.enddatum ? new Date(params.data.enddatum) : null;

          if (abreisedatum && enddatum) {
            const diffTime = enddatum.getTime() - abreisedatum.getTime();
            const diffDays = Math.floor(Math.abs(diffTime / (1000 * 3600 * 24)));

            return `${diffDays} Tage`;
          } else {
            return '-';
          }
        },
        filter: false,
        minWidth: 120,
        maxWidth: 140,
      },
      {
        field: 'garantiert',
        headerName: 'Gar.',
        minWidth: 60,
        maxWidth: 65,
        cellRenderer: params => {
          return params.value
            ? "<i class='fas fa-check text-success fa-lg'></i>"
            : "<i class='ml-1 fas fa-times text-danger fa-lg'></i>";
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          cellRenderer: params =>
            params.value === '(Select All)'
              ? params.value
              : params.value
              ? "<i class='fas fa-check text-success fa-lg'/>"
              : "<i class='ml-1 fas fa-times text-danger fa-lg'/>",
          debounceMs: 1000,
        },
      },
      {
        field: 'vorzeitigGarantiert',
        headerName: 'Vorz. gar.',
        minWidth: 85,
        maxWidth: 85,
        cellRenderer: params => {
          const iconClass = this.vorzeitigGarantiertClass(params.value);
          return `<i class="${iconClass}"></i>`;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['VorzeitigGarantiert', 'WarVorzeitigGarantiert', 'NichtVorzeitigGarantiert'],
          valueFormatter: params =>
            params.value === 'VorzeitigGarantiert'
              ? 'Vorzeitig garantiert'
              : params.value === 'WarVorzeitigGarantiert'
              ? 'War vorzeitig garantiert'
              : params.value === 'NichtVorzeitigGarantiert'
              ? 'Nicht vorzeitig garantiert'
              : params.value,
          debounceMs: 1000,
        },
      },
      {
        field: 'stopSale',
        headerName: 'Stop Sale',
        minWidth: 90,
        maxWidth: 90,
        cellRenderer: params =>
          params.data.stopSale ? '<i class="flaticon2-exclamation mr-1 text-white rounded bg-danger"/>' : '-',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          cellRenderer: params =>
            params.value === '(Select All)'
              ? params.value
              : params.value
              ? '<i class="flaticon2-exclamation mr-1 text-white rounded bg-danger"/>'
              : '-',
        },
      },
      {
        field: 'paxWidget',
        headerName: 'PAX',
        cellRenderer: 'CellRendererPax',
        filter: 'agSetColumnFilter',
        minWidth: 150,
        maxWidth: 180,
        suppressRowTransform: true,
        filterParams: {
          comparator: (a, b) => {
            const ranking = {
              'Ist-Pax = 0': 0,
              'Ist-Pax > 0': 1,
              'Ist-Pax = 1': 2,
              'Ist-Pax = Max-Pax': 3,
              'Ist-Pax < MTN-Pax': 4,
              'Ist-Pax ≥ MTN-Pax': 5,
              'Ist-Pax = MTN-1': 6,
              'Ist-Pax = MTN-2': 7,
              'Ist-Pax = Kalk-Pax': 8,
            };

            if (a === 'null') return 1;
            else if (ranking[a] === ranking[b]) return 0;
            return ranking[a] > ranking[b] ? 1 : -1;
          },
          values: [
            'Ist-Pax = 0',
            'Ist-Pax > 0',
            'Ist-Pax = 1',
            'Ist-Pax = Max-Pax',
            'Ist-Pax < MTN-Pax',
            'Ist-Pax ≥ MTN-Pax',
            'Ist-Pax = MTN-1',
            'Ist-Pax = MTN-2',
            'Ist-Pax = Kalk-Pax',
          ],
          debounceMs: 1000,
          valueFormatter: params => (params.value === 'InArbeit' ? 'In Arbeit' : params.value),
          cellRenderer: params => this.paxFilterFormatterSmallHtml[params.value] ?? '(Select all)',
        },
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'justify-items': 'center',
          overflow: 'visible',
          'align-items': 'center',
          'padding-bottom': '8px',
        },
        cellRendererParams: params => ({
          paxObject: params.data.pax,
          kalkPax: params.data.metadata.kalkPax,
          erwartetePax: params.data.metadata.erwartetePax,
          durchfuehrbareMinPax: params.data.metadata.durchfuehrbareMinPax,
          id: params.data.id,
        }),
      },
      {
        field: 'releasedatum',
        headerName: 'Releasedatum',
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        minWidth: 145,
        maxWidth: 170,
        sortable: true,
        floatingFilter: true,
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
      },
      {
        field: 'umsatz',
        headerName: 'Umsatz',
        headerComponent: 'RangeNumberFilterCustomHeader',
        minWidth: 120,
        // maxWidth: 135,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: { buttons: ['apply'] },
        cellRenderer: params => (params.value ? this.$options.filters.currency(params.value) : '-'),
      },

      {
        field: 'action',
        headerName: 'Aktionen',
        filter: false,
        sortable: false,
        hide: false,
        maxWidth: 150,
        cellRenderer: function (params) {
          const linkReiseterminDetailseite = `/#/reisetermine/${params.data.id}`;
          const linkReisestammdaten = `/#/reisen/${params.data.reise.reisekuerzel}/reisestammdaten`;
          return `
      <div>
        <a href="${linkReiseterminDetailseite}" target="_blank" class="badge badge-secondary action-button mr-2" size="sm">
          <i class="fas fa-info"></i>
        </a>
        ${
          params.context.isUserAllowedToEdit
            ? `
          <a href="${linkReisestammdaten}" target="_blank" class="badge badge-secondary action-button" size="sm">
            <i class="fas fa-pen"></i>
          </a>
        `
            : ''
        }
      </div>`;
        },
        pinned: 'right',
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
        },
      },
    ];
  },
  computed: {
    ...mapGetters(['getReisekuerzelOptions', 'parsedRoles', 'regionFilterOptions']),
    reisenOptions() {
      return this.getReisekuerzelOptions.map(r => r.reisekuerzel);
    },
    regionOptions() {
      return this.getReisekuerzelOptions.map(r => r.region);
    },
    countryOptions() {
      return this.getReisekuerzelOptions.map(r => r.country);
    },
    isUserAllowedToEdit() {
      return (
        this.parsedRoles.includes('Admin') ||
        this.parsedRoles.includes('Pm') ||
        this.parsedRoles.includes('Yield')
      );
    },
  },
  methods: {
    vorzeitigGarantiertClass,
    onRowClicked(row) {
      const isMasterRow = row?.node?.master;
      if (!isMasterRow) return;

      const filter = {
        hotelleistungen: {
          any: {
            isDeleted: false,
            reiseterminId: parseInt(row.data.id),
          },
        },
      };

      // Check for Vorgänge before expanding
      odataService
        .getVorgang({ filter })
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.gridApi.forEachNode(node => {
              if (node.data?.reiseterminkuerzel === row.data.reiseterminkuerzel) {
                this.gridApi.setRowNodeExpanded(node, !node.expanded);
              }
            });
          }
        })
        .catch(error => {
          console.error('Error checking for Vorgänge:', error);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.gridApi?.onFilterChanged();

      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        params.api.setServerSideDatasource(datasource);
      };

      updateData();
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          console.log('[Datasource] - rows requested by grid: ', request);
          const odataFilter = this.getOdataFilterFromAgGridRequest(request);
          this.lastOdataFilter = odataFilter;

          this.checkIfFilterSupplied();
          const selectedReiseterminVariables = [
            'reiseterminkuerzel',
            'state',
            'startdatum',
            'garantiert',
            'vorzeitigGarantiert',
            'stopSale',
            'releasedatum',
            'umsatz',
            'abreisedatum',
            'enddatum',
            'id',
          ];

          const expand = {
            pax: { select: ['min', 'max', 'ist', 'opt', 'anf', 'war', 'vor'] },
            reise: { select: ['reisekuerzel', 'country', 'region'] },
            metadata: {
              select: ['durchfuehrbareMinPax', 'erwartetePax', 'kalkPax'],
            },
          };

          const response = await odataService.getReisetermin({
            filter: odataFilter,
            top: request.endRow - request.startRow,
            select: selectedReiseterminVariables,
            skip: request.startRow,
            count: true,
            expand,
            ...(request.sortModel.length > 0
              ? {
                  orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
                }
              : { orderBy: 'reise/reisekuerzel asc, startdatum asc' }),
          });
          this.count = response.count;
          return {
            success: true,
            rows: response.data,
          };
        },
      };
    },
    getOdataFilterFromAgGridRequest(request) {
      this.checkIfFilterSupplied();

      const filterKeys = Object.keys(request.filterModel);
      let filters = [];

      const allFilterKeys = filterKeys.map(key => {
        const formattedKey = key.replaceAll('.', '/');
        if (request.filterModel[key].filterType === 'text') {
          if (['umsatz'].includes(formattedKey)) {
            if (request.filterModel[key].filter.includes('-')) {
              const min = parseInt(request.filterModel[key].filter.split('-')[0]);
              const max = parseInt(request.filterModel[key].filter.split('-')[1]);
              return { [formattedKey]: { le: max, ge: min } };
            } else if (request.filterModel[key].filter[0] === '>') {
              const min = parseInt(request.filterModel[key].filter.split('>')[1]);
              return { [formattedKey]: { gt: min } };
            } else if (request.filterModel[key].filter[0] === '<') {
              const max = parseInt(request.filterModel[key].filter.split('<')[1]);
              return { [formattedKey]: { lt: max } };
            } else return { [formattedKey]: parseInt(request.filterModel[key].filter) };
          }
          return { [formattedKey]: { startswith: request.filterModel[key].filter } };
        } else if (request.filterModel[key].filterType === 'date') {
          const dateFrom = new Date(request.filterModel[key].dateFrom.substring(0, 10));
          const dateTo = add(new Date(request.filterModel[key].dateTo), { hours: 23, minutes: 59 });

          return {
            [formattedKey]: {
              ge: dateFrom,
              le: dateTo,
            },
          };
        } else if (request.filterModel[key].filterType === 'set') {
          if (formattedKey === 'paxWidget') {
            const { values } = request.filterModel[key];
            const paxFilterConverter = {
              'Ist-Pax = 0': { ['pax/ist']: { eq: 0 } },
              'Ist-Pax > 0': { ['pax/ist']: { gt: 0 } },
              'Ist-Pax = 1': { ['pax/ist']: { eq: 1 } },
              'Ist-Pax = Max-Pax': { ['pax/ist']: { eq: { type: 'guid', value: 'pax/max' } } },
              'Ist-Pax < MTN-Pax': {
                and: [
                  { ['pax/ist']: { lt: { type: 'guid', value: 'pax/min' } } },
                  { ['pax/ist']: { gt: 0 } },
                ],
              },
              'Ist-Pax ≥ MTN-Pax': {
                and: [{ ['pax/ist']: { ge: { type: 'guid', value: 'pax/min' } } }],
              },
              'Ist-Pax = MTN-1': {
                and: [
                  { ['pax/min']: { eq: { type: 'guid', value: 'pax/ist add 1' } } },
                  { ['pax/ist']: { gt: 0 } },
                ],
              },
              'Ist-Pax = MTN-2': {
                and: [
                  { ['pax/min']: { eq: { type: 'guid', value: 'pax/ist add 2' } } },
                  { ['pax/ist']: { gt: 0 } },
                ],
              },
              'Ist-Pax = Kalk-Pax': { ['pax/ist']: { eq: { type: 'guid', value: 'metadata/kalkPax' } } },
            };

            if (values.length === 1) {
              return paxFilterConverter[values[0]];
            }

            return { or: [...values.map(value => paxFilterConverter[value])] };
          }
          return { [formattedKey]: { in: request.filterModel[key].values } };
        }
      });
      filters = [
        ...filters,
        {
          isDeleted: { ne: true },
        },
        ...allFilterKeys,
      ];

      if (this.prioritiesSelected.length > 0) {
        filters = [
          ...filters,
          {
            ['reise/prioritaet']: {
              in: this.prioritiesSelected.map(
                prioritaet => this.priorities.find(p => p.text === prioritaet)?.value
              ),
            },
          },
        ];
      }

      return filters;
    },
    checkIfFilterSupplied() {
      const usedFilters = Object.keys(this.gridApi?.getFilterModel()).filter(item => item !== 'startdatum');
      const startdatumFilter = this.gridApi.getFilterInstance('startdatum').getModel();

      if (startdatumFilter == null) {
        this.isFilterSupplied = true;
        return;
      }
      // werte werden aus dem startdatum Filter geholt
      const { dateFrom, dateTo } = startdatumFilter;
      const today = new Date();
      const defaultEndValue = new Date(2025, 11, 31);

      //gibt einen Boolean zurück, ob die Werte mit dem default werden übereinstimmen
      const changedStartValue = new Date(dateFrom).toDateString() === today.toDateString();
      const changedEndValue = new Date(dateTo).toDateString() === defaultEndValue.toDateString();

      this.isFilterSupplied =
        this.gridApi &&
        (usedFilters.length > 0 ||
          this.prioritiesSelected.length > 0 ||
          !(changedStartValue && changedEndValue));
    },
    onFilterChanged() {
      this.checkIfFilterSupplied();
    },
    onPriorityClick($event) {
      const { id: priorityText } = $event.target;
      if (priorityText === 'Alle') {
        this.prioritiesSelected = [];
      } else if (!this.prioritiesSelected.includes(priorityText)) {
        this.prioritiesSelected.push(priorityText);
      } else {
        this.prioritiesSelected = this.prioritiesSelected.filter(priority => priority !== priorityText);
      }
      // Wenn alle angeklickt sind, resette
      if (this.prioritiesSelected.length === this.priorities.length - 1) {
        this.prioritiesSelected = [];
      }

      this.gridApi.onFilterChanged();
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    onReload() {
      this.gridApi.onFilterChanged();
    },
    onRowDoubleClicked($event) {
      const { data, rowIndex } = $event;
      this.selectedRowID = rowIndex;
      const routeData = this.$router.resolve({ path: '/reisetermine/' + data.id });
      window.open(routeData.href, '_blank');
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      this.prioritiesSelected = [];

      let startdatum = this.gridApi.getFilterInstance('startdatum');
      if (startdatum) {
        const start = new Date();
        const end = new Date(2025, 11, 31);
        startdatum.onDateRangeFilterChanged([start, end]);
      }

      let releasedatum = this.gridApi.getFilterInstance('releasedatum');
      if (releasedatum) {
        releasedatum.onDateRangeFilterChanged(null);
      }

      // const query = { ...this.$route.query };
      // delete query['reisetermine'];
      this.checkIfFilterSupplied();
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-cell),
:deep(.ag-header-cell) {
  padding: 0px 0.5rem;
}
:deep(.dots-dropdown) {
  font-weight: 600;
  font-size: 14px;
}
:deep(.ag-row-group-expanded) {
  background-color: #e5f0fb;
}
</style>

<template>
  <div class="row m-0">
    <div class="col-md-12 gutter-b">
      <div class="card card-custom card-fit card-border">
        <div class="card-body">
          <VorgangTable
            :isLoading="isLoadingVorgaenge"
            :displayFieldsByKey="['id', 'kunde', 'reisezeitraum', 'zimmer', 'npx', 'agentur.id', 'stateSlot']"
            :items="vorgaenge"
            :reiseterminId="reiseterminObject.id"
            @update-single-item="onUpdateSingleItem"
          >
            <template #stateSlot="row">
              <VorgangStateWidget :state="row.item.blankState" />
            </template>
          </VorgangTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as odataService from '@/core/common/services/odata.service';

import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';

const moment = require('moment');

export default {
  name: 'ReiseterminTableDetails',
  components: { VorgangTable, VorgangStateWidget, ReiseterminViewBesonderheitenBase },
  props: {
    reiseterminObject: { type: Object },
  },
  data() {
    return {
      vorgaenge: [],
      isLoadingVorgaenge: true,
      expand: {
        hotelleistungen: {
          filter: {
            isDeleted: false,
          },
          expand: {
            Reisetermin: {
              filter: {
                isDeleted: false,
              },
              expand: { Reise: {}, pax: {}, gueltigeKonfiguration: {} },
            },
          },
        },
        agentur: { select: ['id'] },
        kunde: {
          expand: {
            master: {
              expand: { kunden: {} },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    reisezeitraum() {
      if (!this.reiseterminObject) return '-';
      const reisedauer = this.reiseterminObject?.reisedauer ? this.reiseterminObject?.reisedauer - 1 : 0;
      const momentUntilDate = moment(this.reiseterminObject.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObject?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObject.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
  },
  methods: {
    getData() {
      this.getVorgaenge(this.reiseterminObject.id);
    },
    getVorgaenge(id) {
      const filter = {
        hotelleistungen: {
          any: {
            isDeleted: false,
            reiseterminId: parseInt(id),
          },
        },
      };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          this.vorgaenge = response.data;
          this.isLoadingVorgaenge = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async onUpdateSingleItem(index, id) {
      const newVorgang = await this.getVorgangById(id);
      this.$set(this.vorgaenge, index, newVorgang);
    },
    async getVorgangById(id) {
      this.isLoading = true;
      const filter = { id: id };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.more-btn {
  cursor: pointer;
}
table#vorgaengeTable > thead > tr > th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>

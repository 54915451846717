<template>
  <div class="p-4" style="display: flex; gap: 8px; background-color: #e5f0fb">
    <ReiseterminTableDetails :reiseterminObject="params.data"></ReiseterminTableDetails>
  </div>
</template>

<script>
import ReiseterminTableDetails from '@/components/produkte/reisetermine/reisetermine-table-details.vue';

export default {
  components: {
    ReiseterminTableDetails,
  },
};
</script>

<style></style>
